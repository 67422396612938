/* Apply custom styles to the dropdown */
.custom-dropdown {
    max-height: 15rem; /* Adjust height as needed */
    overflow-y: scroll; /* Ensure scrollbar is always visible */
}

/* Custom scrollbar styles for Webkit browsers (Chrome, Safari, etc.) */
.custom-dropdown::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
    height: 8px; /* Optional: width of horizontal scrollbar */
}

/* Custom scrollbar track */
.custom-dropdown::-webkit-scrollbar-track {
    background: transparent; /* Make the track background transparent */
}

/* Custom thumb (draggable part) */
.custom-dropdown::-webkit-scrollbar-thumb {
    background-color: #4B5D67; /* Thumb color */
    border-radius: 20px;
    border: 2px solid transparent; /* Optional: create a gap around the thumb */
    background-clip: content-box; /* Prevents the border from interfering with the thumb color */
}

/* Thumb color on hover */
.custom-dropdown::-webkit-scrollbar-thumb:hover {
    background-color: #3A4C55; /* Darker thumb color on hover */
}

/* Hide the arrows in the scrollbar */
.custom-dropdown::-webkit-scrollbar-button {
    display: none;
}




