@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @keyframes typewriter-reveal {
        from {
            max-height: 0;
            opacity: 0;
        }
        to {
            max-height: 1000px; /* Large enough to cover all content */
            opacity: 1;
        }
    }

    .animate-typewriter {
        animation: typewriter-reveal 1.5s ease-in-out forwards;
        overflow: hidden; /* Hide content until it "types" in */
    }
}

@layer utilities {
    @keyframes typewriter-fade {
        from {
            max-height: 0;
            opacity: 0;
            transform: translateY(10px);
        }
        to {
            max-height: 1000px;
            opacity: 1;
            transform: translateY(0);
        }
    }

    .animate-typewriter-fade {
        animation: typewriter-fade 1.5s ease-in-out forwards;
        overflow: hidden;
    }
}
@layer utilities {
    @keyframes typewriter-left-to-bottom {
        0% {
            width: 0%;
            height: 0%;
            opacity: 0;
        }
        50% {
            width: 100%;
            height: 0%;
            opacity: 1;
        }
        100% {
            width: 100%;
            height: 100%;
            opacity: 1;
        }
    }

    .animate-left-to-bottom {
        animation: typewriter-left-to-bottom 2s ease-in-out forwards;
        overflow: hidden;
    }
}

@layer utilities {
    @keyframes typewriter-slide {
        0% {
            width: 50%;
            height: 10%;
            opacity: 0;
            transform: translateY(-20px);
        }
        50% {
            width: 100%;
            height:30%;
            opacity: 1;
            transform: translateY(0);
        }
        100% {
            width: 100%;
            height: 100%;
            opacity: 1;
            transform: translateY(0);
        }
    }

    .animate-slide-left-bottom {
        animation: typewriter-slide 4s ease-in-out forwards;
        overflow: hidden;
    }
}


/*@layer utilities {*/
/*    @keyframes typewriter-slide {*/
/*        0% {*/
/*            height: 0%;*/
/*            opacity: 0;*/
/*            transform: translateY(-20px);*/
/*        }*/
/*        50% {*/
/*            height: 100%;*/
/*            opacity: 1;*/
/*            transform: translateY(0);*/
/*        }*/
/*        100% {*/
/*            height: 100%;*/
/*            opacity: 1;*/
/*            transform: translateY(0);*/
/*        }*/
/*    }*/

/*    .animate-slide-left-bottom {*/
/*        animation: typewriter-slide 6s ease-in-out forwards;*/
/*        overflow: hidden;*/
/*        display: block;*/
/*    }*/
/*}*/

@layer utilities {
    @keyframes slide-top-to-bottom {
        0% {
            max-height: 0;
            opacity: 0;
            transform: translateY(-20px); /* Slide from top */
        }
        100% {
            max-height: 1000px; /* Large enough to cover content */
            opacity: 1;
            transform: translateY(0); /* Slide back to original position */
        }
    }


    .animate-slide-top-bottom {
        animation: slide-top-to-bottom 1.5s ease-in-out forwards;
        overflow: hidden;
    }
}

