/* Custom checkbox styles */
.custom-checkbox input[type="checkbox"]:checked {
    background-color: black;
    border-color: black;
}
input[type="checkbox"]:checked {
    filter: invert(1) brightness(0) saturate(100%);
    /* filter: invert(1) brightness(2) hue-rotate(240deg); */
}

/* input[type="checkbox"]:nth-child(1):checked {
    filter: hue-rotate(240deg);
} */