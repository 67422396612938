.no-scrollbar::-webkit-scrollbar {
    display: none;
}
.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
/* width */
::-webkit-scrollbar {
    width: 5px;
    height: 4px;
}