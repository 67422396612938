.custom-autocomplete .scrollable-input input {
    overflow-x: auto;
    white-space: nowrap;
}

.custom-autocomplete .ant-input-affix-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    overflow-x: auto;
    padding-right: 8px;
}

.custom-autocomplete .ant-input-affix-wrapper input {
    flex-grow: 1;
    border: none;
    outline: none;
}
